import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Grid, Button } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import styles from './styles.module.css';
import { Line } from 'react-chartjs-2';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import SalesReportsService from '../../../services/SalesReportsService';
import Loader from '../../utils/Loading';
import setDelay from '../../../helpers/LoadingDelay';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import UpgradeToPremiumMessage from '../../utils/UpgradeToPremiumMessage';
import { SUBSCRIPTION_TYPE, USER_PERMISSIONS } from '../../../const';
import { ShopContext } from '../../../Context/ShopContext';
import { UserContext } from '../../../Context/UserContext';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import ShopsHelper from '../../../helpers/ShopsHelper';

Chart.register(CategoryScale);

const TIME_RANGE = {
  TODAY: 'today',
  WEEK: 'week',
  MONTH: 'month',
  SIX_MONTHS: 'six-months',
};

function Dashboard() {
  const { user } = useContext(UserContext);
  const { shop } = useContext(ShopContext);

  const [filterDateRange, setFilterDateRange] = useState(TIME_RANGE.TODAY);
  const [salesGraphData, setSalesGraphData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Sales graph',
        backgroundColor: '#605ca8',
        borderColor: '#605ca8',
        borderWidth: 1,
        data: [],
      },
    ],
  });
  const [summaryReport, setSummaryReport] = useState({});
  const [isChartLoading, setIsChartLoading] = useState(false);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const updateByDateResponseData = (data) => {
    if (data.length === 1) data = [{ date: '', amount: 0 }, ...data];
    setSalesGraphData({
      labels: data.map((sale) => sale.date),
      datasets: [
        {
          label: 'Sales graph',
          backgroundColor: '#605ca8',
          borderColor: '#605ca8',
          borderWidth: 1,
          data: data.map((sale) => sale.amount),
        },
      ],
    });
  };

  const dateRangeMap = {
    [TIME_RANGE.TODAY]: DateTimeHelpers.getTodayStrInYMD(),
    [TIME_RANGE.WEEK]: DateTimeHelpers.getWeekStr(),
    [TIME_RANGE.MONTH]: DateTimeHelpers.getMonthStr(),
    [TIME_RANGE.SIX_MONTHS]: DateTimeHelpers.getSixMonthStr(),
  };
  
  const _getDateRangeData = useCallback((_dateRange) => {
    const from_date = dateRangeMap[_dateRange] || '';
    return { from_date, to_date: DateTimeHelpers.getTodayStrInYMD() };
    // eslint-disable-next-line
  }, []);

  const _hasReportsGeneratePermission = useCallback(() => {
    return ((user || {}).shop_permissions || {}).reports_permission === USER_PERMISSIONS.WRITE;
  }, [user]);

  const loadReports = useCallback(
    async (_dateRange) => {
      if (!_hasReportsGeneratePermission()) {
        return;
      }
  
      setIsChartLoading(true);
      setLoadingIndicator(true);
      setErrorMessage('');
  
      try {
        const data = _getDateRangeData(_dateRange);
        const [salesByDate, summaryReport] = await Promise.all([
          SalesReportsService.getSalesByDate(data),
          SalesReportsService.getSalesSummary(data),
        ]);
        updateByDateResponseData(salesByDate.reverse());
        setSummaryReport(summaryReport);
      } catch (err) {
        console.error(err.message);
        setErrorMessage('Failed to load sales data. Please try again later.');
      } finally {
        setIsChartLoading(false);
        setDelay(setLoadingIndicator);
      }
    },
    [_getDateRangeData, _hasReportsGeneratePermission]
  );

  useEffect(() => {
    let isMounted = true;
    loadReports(filterDateRange).then(() => {
      if (isMounted) setLoadingIndicator(false);
    });
    return () => {
      isMounted = false;
    };
  }, [filterDateRange, loadReports]);  

  if (!_hasReportsGeneratePermission()) {
    return <>{user ? <div>You do not have permission to generate reports</div> : <div>Loading...</div>}</>;
  }

  const subscriptionType = shop?.subscription?.type || '';

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <span className={styles.title}>
          Sales <span className={styles.menuName}>Dashboard</span>
        </span>
      </div>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="flex-start"
            className={styles.priceCard}
            style={{ backgroundColor: '#77ACF1' }}
          >
            <h2 className={styles.price}>
              {shop?.currency || ''}
              <span className={styles.count}>
                {ShopsHelper.getAmountFormatted(shop, summaryReport.total_amount || 0.0)}
              </span>
            </h2>
            <h3 className={styles.summaryLabelBig}>Total sales</h3>
            <div className={styles.iconSec}>
              <ShoppingCartIcon className={styles.icon} />
            </div>
            <div className={styles.invoicesDetails}>
              <h3 className={styles.category}>{summaryReport.no_of_invoices || 0}</h3>
              <h3 className={styles.category}>Invoices</h3>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="flex-start"
            className={styles.priceCard}
            style={{ backgroundColor: '#FF7F00' }}
          >
            <h2 className={styles.price}>
              {shop?.currency || ''}
              <span className={styles.count}>
                {ShopsHelper.getAmountFormatted(shop, summaryReport.total_cost || 0)}
              </span>
            </h2>
            <h3 className={styles.summaryLabelBig}>Total cost</h3>
            <div className={styles.iconSec}>
              <DescriptionIcon className={styles.icon} />
            </div>
            <div className={styles.invoicesDetails}>
              <h3 className={styles.category}>
                {shop?.currency || ''}
                <span className={styles.count}>
                  {ShopsHelper.getAmountFormatted(shop, summaryReport.total_tax || 0)}
                </span>
              </h3>
              <h3 className={styles.category}>Purchase tax</h3>
            </div>
            {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.PREMIUM && (
              <div className={styles.coverParentFull}>
                <UpgradeToPremiumMessage />
              </div>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="flex-start"
            className={styles.priceCard}
            style={{ backgroundColor: '#00C0EF' }}
          >
            <h2 className={styles.price}>
              {shop?.currency || ''}
              <span className={styles.count}>{ShopsHelper.getAmountFormatted(shop, summaryReport.profit || 0.0)}</span>
            </h2>
            <h3 className={styles.summaryLabelBig}>Profit</h3>
            <div className={styles.iconSec}>
              <LocalOfferIcon className={styles.icon} />
            </div>
            <div className={styles.invoicesDetails}>
              <h3 className={styles.category}>
                {shop?.currency || ''}
                <span className={styles.count}>
                  {ShopsHelper.getAmountFormatted(shop, summaryReport.damage_value || 0)}
                </span>
              </h3>
              <h3 className={styles.category}>Damage value</h3>
            </div>
            {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.PREMIUM && (
              <div className={styles.coverParentFull}>
                <UpgradeToPremiumMessage />
              </div>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="flex-start"
            className={styles.priceCard}
            style={{ backgroundColor: '#ff0068' }}
          >
            <h2 className={styles.price}>
              {shop?.currency || ''}
              <span className={styles.count}>
                {ShopsHelper.getAmountFormatted(shop, summaryReport.total_discount || 0.0)}
              </span>
            </h2>
            <h3 className={styles.summaryLabelBig}>Discount</h3>
            <div className={styles.iconSec}>
              <FlashOnIcon className={styles.icon} />
            </div>
            {subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.PREMIUM && (
              <div className={styles.invoicesDetails}>
                <h3 className={styles.category}>
                  {shop?.currency || ''}
                  <span className={styles.count}>
                    {ShopsHelper.getAmountFormatted(shop, summaryReport.total_expense || 0)}
                  </span>
                </h3>
                <h3 className={styles.category}>Expense</h3>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
      <div className={styles.changeable}>
        {errorMessage && (
          <div className={styles.errorMessage}>
            <p>{errorMessage}</p>
          </div>
        )}
        <div className={styles.secTitle}>
          <h2 className={styles.chartTitle}>Sales overview chart</h2>
        </div>
        <Grid container spacing={3}>
          <Grid item sm={12} md={9}>
            {isChartLoading ? (
              <Loader isOpen={true} />
            ) : (
              <Line
                data={salesGraphData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'bottom',
                    },
                  },
                }}
              />
            )}
          </Grid>
          <Grid item sm={12} md={3}>
            <h2 className={styles.filterTitle}>Filters</h2>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Button
                  variant={filterDateRange === TIME_RANGE.TODAY ? 'contained' : 'outlined'}
                  fullWidth
                  color="primary"
                  onClick={() => {
                    setFilterDateRange(TIME_RANGE.TODAY);
                  }}
                >
                  TODAY
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant={filterDateRange === TIME_RANGE.WEEK ? 'contained' : 'outlined'}
                  fullWidth
                  color="primary"
                  onClick={() => {
                    setFilterDateRange(TIME_RANGE.WEEK);
                  }}
                >
                  LAST WEEK
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant={filterDateRange === TIME_RANGE.MONTH ? 'contained' : 'outlined'}
                  fullWidth
                  color="primary"
                  onClick={() => {
                    setFilterDateRange(TIME_RANGE.MONTH);
                  }}
                >
                  LAST MONTH
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant={filterDateRange === TIME_RANGE.SIX_MONTHS ? 'contained' : 'outlined'}
                  fullWidth
                  color="primary"
                  onClick={() => {
                    setFilterDateRange(TIME_RANGE.SIX_MONTHS);
                  }}
                >
                  LAST 6 MONTHS
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default withConsoleBase(Dashboard);
