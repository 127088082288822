import BackendService from './BackendService';

async function getCustomers() {
  return BackendService.getData('/customers/');
}
async function getCustomersWithCount(params) {
  return BackendService.get('/customers/', {}, params);
}
async function getCustomer(id) {
  return BackendService.getData(`/customers/${id}/`);
}
async function getCustomerJournals(id, params) {
  return BackendService.get(`/customers/${id}/journals/`, {}, params);
}
async function createCustomer(data) {
  return BackendService.postData('/customers/', data);
}
async function updateCustomer(id, data = {}) {
  return BackendService.put(`/customers/${id}/`, data);
}
async function updateCustomers(data = {}) {
  return BackendService.post(`/customers/batch/`, data);
}
async function deleteCustomer(id) {
  return BackendService.destroy(`/customer/${id}/`, '');
}
async function deleteCustomerPurchase(data) {
  return BackendService.destroy(`/sales/customer/`, data);
}
async function removeCustomerFromSale(id) {
  return BackendService.destroy(`/customers/unset-sale/${id}/`);
}
async function assignCustomer(data) {
  return BackendService.postData('/sales/customer/', data);
}
async function getLastFourBill(id) {
  return BackendService.getData(`/sales/customer/?customer_id=${id}`);
}
async function getCustomerSaleDetails(id) {
  return BackendService.getData(`/sales/${id}/`);
}
async function addPaymentToCustomer(id, data) {
  return BackendService.post(`/customers/${id}/journals/`, data);
}
async function getCustomerLoyaltyLogs(id, data) {
  // return BackendService.post(`/customer/${id}/payment`, data);
  await new Promise((resolve) => {
    setTimeout(resolve, 3000);
  });
}
async function getCustomerGroups() {
  return BackendService.getData('/customer-groups/');
}

async function updateCustomerGroups(id, data) {
  return BackendService.patch(`/customer-groups/${id}/`, data);
}

async function createCustomerGroup(data) {
  return BackendService.postData(`/customer-groups/`, data);
}

const CustomerService = {
  getCustomers,
  getCustomer,
  createCustomer,
  updateCustomer,
  updateCustomers,
  deleteCustomer,
  assignCustomer,
  getLastFourBill,
  deleteCustomerPurchase,
  getCustomerJournals,
  getCustomersWithCount,
  getCustomerSaleDetails,
  removeCustomerFromSale,
  addPaymentToCustomer,
  getCustomerLoyaltyLogs,
  getCustomerGroups,
  updateCustomerGroups,
  createCustomerGroup,
};

export default CustomerService;
