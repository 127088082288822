import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable/';
import NewCustomerDialog from '../../popups/NewCustomerDialog';
import EditCustomerDialog from '../../popups/EditCustomerDialog';
import CustomerService from '../../../services/CustomerService';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Loader from '../../utils/Loading';
import setDelay from '../../../helpers/LoadingDelay';
import Info from '../../utils/Alert/Info';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';
import { ShopContext } from '../../../Context/ShopContext';
import { UserContext } from '../../../Context/UserContext';
import { ROUTES, USER_PERMISSIONS } from '../../../const';
import { useHistory } from 'react-router-dom';
import { Paper, TablePagination, Typography, Tooltip, IconButton, Grid } from '@mui/material';
import { AlertMessagesContext } from 'react-alert-messages';
import ShopsHelper from '../../../helpers/ShopsHelper';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import CsvHelper from '../../../helpers/CsvHelper';
import { saveAs } from 'file-saver';
import ArrayHelper from '../../../helpers/ArrayHelper';
import SyncIcon from '@mui/icons-material/Sync';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

function Customers() {
  const { shop } = useContext(ShopContext);
  const { user } = useContext(UserContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const history = useHistory();

  const [customers, setCustomers] = useState();
  const [filterText, setFilterText] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [isShowCreateDialog, setIsShowCreateDialog] = useState(false);
  const [editData, setEditData] = useState(null);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [count, setCount] = useState(0);
  const [isEnableSearchRefreshButton, setIsEnableSearchRefreshButton] = useState(false);
  const [customerGroups, setCustomrGroups] = useState([]);

  const userHasCustomersWritePermission = user?.shop_permissions?.customers_permission === USER_PERMISSIONS.WRITE;

  const openEditCustomerDialog = (customer) => {
    setEditData(customer);
  };

  const closeEditCustomerDialog = () => {
    setEditData(null);
  };

  const openCreateNewCustomerDialog = () => {
    setIsShowCreateDialog(true);
  };

  const closeCreateNewCustomerDialog = () => {
    setIsShowCreateDialog(false);
  };

  const getCustomers = async ({ _offset = offset, _limit = limit } = {}) => {
    setLoadingIndicator(true);
    try {
      const res = await CustomerService.getCustomersWithCount({
        offset: _offset,
        limit: _limit,
        search: filterText,
      });
      setCustomers(res?.data);
      setCount(res?.count);
      setIsEnableSearchRefreshButton(false);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setDelay(setLoadingIndicator);
  };

  const redirectToCustomerrDetails = async (data) => {
    history.push(`${ROUTES.CUSTOMERS}/${data.id}`);
  };

  const getBalance = (data) => {
    const balance = ShopsHelper.getAmountFormatted(shop, data?.balance);
    let balanceStyle;
    if (balance >= 0) balanceStyle = { color: 'green' };
    else if (balance > data?.credit_limit) balanceStyle = { color: 'orange' };
    else balanceStyle = { color: 'red' };
    return (
      <Typography variant="body2" style={balanceStyle} gutterBottom>
        {balance}
      </Typography>
    );
  };

  const CSV_COLUMNS = {
    name: 'Name',
    email: 'Email',
    mobile: 'Mobile',
    location: 'Location',
    balance: 'Balance',
    credit_limit: 'Credit Limit',
    trn_number: 'TRN',
    code: 'Code',
    group: {
      title: 'Group',
      getValue: (item) => {
        return item?.group?.name || '';
      },
    },
  };

  const headerData = [
    {
      label: 'ID',
      id: 'id',
      type: 'text',
    },
    {
      label: 'Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Mobile',
      id: 'mobile',
      type: 'text',
    },
    {
      label: 'Place',
      id: 'location',
      type: 'text',
    },
    {
      label: 'Group',
      id: 'group',
      type: 'callback',
      viewRender: (data) => data?.group?.name ?? '-',
    },
    {
      label: 'Credit Limit',
      id: 'credit_limit',
      type: 'text',
    },
    {
      label: 'TRN',
      id: 'trn_number',
      type: 'text',
    },
    {
      label: 'Balance',
      id: 'balance',
      type: 'callback',
      viewRender: (data) => getBalance(data),
    },
    {
      label: 'Points',
      id: 'points',
      type: 'text',
    },
    {
      label: 'Actions',
      id: 'actions',
      type: 'callback',
      viewRender: (item) => {
        return (
          <Grid className={styles.btnGroup}>
            <Tooltip title="View Details" onClick={() => redirectToCustomerrDetails(item)}>
              <RemoveRedEyeIcon className={styles.iconButton} />
            </Tooltip>
            {userHasCustomersWritePermission && (
              <>
                <Tooltip title="Edit Customer" onClick={() => openEditCustomerDialog(item)}>
                  <EditIcon className={styles.iconButton} />
                </Tooltip>
              </>
            )}
          </Grid>
        );
      },
    },
  ];

  const toCsvBtnPressed = async () => {
    const csv = CsvHelper.getString(customers, CSV_COLUMNS);
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `customer-${new Date().toISOString()}.csv`);

    postAlertMessage({
      text: 'Exported to excel successfully',
      type: 'success',
    });
  };

  const handleImportCsv = async (csvFile) => {
    setLoadingIndicator(true);

    try {
      const csvData = await CsvHelper.parseCsvFile(csvFile);
      const customers = csvData.map((row, index) => {
        if (!row.Name) {
          throw new Error('Name must be provided and cannot be empty.');
        } else if (!row.Mobile) {
          throw new Error('Mobile must be provided and cannot be empty.');
        }
        const group = customerGroups.find((g) => g.name === row.Group) || {};
        return {
          name: row.Name,
          email: row.Email,
          mobile: row.Mobile,
          ean: row.EAN,
          location: row.Location,
          balance: Number(row.Balance),
          credit_limit: Number(row?.['Credit Limit']),
          trn_number: row.TRN,
          code: row.Code,
          group: {
            id: group.id || null,
            name: row.Group || null,
          },
        };
      });

      const alertId = 'customers-batch-update';
      const customersChunked = ArrayHelper.chunk(customers, 40);
      postAlertMessage({
        key: alertId,
        text: `Updating customers as ${customersChunked.length} segments`,
        timeout: -1,
      });
      for (const [index, customersChunk] of customersChunked.entries()) {
        await CustomerService.updateCustomers(customersChunk);
        postAlertMessage({
          key: alertId,
          text: `${index + 1}th chunk uploaded successfully`,
          timeout: -1,
        });
      }

      getCustomers();

      postAlertMessage({
        key: alertId,
        text: 'Customers imported successfully',
        type: 'success',
      });
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed', timeout: 5000 });
    }
    setLoadingIndicator(false);
  };

  const customerFeatureIsExpired = () => {
    const currentDate = new Date();
    const validityDate = new Date(shop.customer_support_validity);
    return shop.customer_support_validity == null || currentDate > validityDate;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    let offset = newPage * limit;
    setOffset(offset);
    getCustomers({ _offset: offset });
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(event.target.value);
    getCustomers({ _limit: limit });
  };

  const getCustomerGroups = async () => {
    const resp = await CustomerService.getCustomerGroups();
    setCustomrGroups(resp);
  };

  useEffect(() => {
    if (!shop?.id || customerFeatureIsExpired()) return;
    getCustomers();
    getCustomerGroups();
    //eslint-disable-next-line
  }, [shop?.id]);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Customer<span className={styles.menuTitle}>Management</span>
        </h2>
        {shop && shop?.customer_support_validity !== null && (
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 5 }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ paddingBottom: '4px' }}>
                <label className={styles.label}>Export As</label>
              </div>
              <Button
                variant="contained"
                color="primary"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={toCsvBtnPressed}
              >
                <ImportExportIcon className={styles.actionBtnIcon} />
                CSV
              </Button>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ paddingBottom: '4px' }}>
                <label className={styles.label}>Import As</label>
              </div>
              <Button
                variant="contained"
                color="primary"
                className={styles.actionBtn}
                style={{ backgroundColor: '#676dd8' }}
              >
                <input
                  accept="text/csv"
                  type="file"
                  hidden
                  id="import"
                  onChange={(event) => {
                    const fileField = event.target;
                    const csvFile = fileField.files[0];
                    fileField.value = null;
                    handleImportCsv(csvFile);
                  }}
                />
                <ImportExportIcon className={styles.actionBtnIcon} />
                <label for="import">CSV</label>
              </Button>
            </div>
          </div>
        )}
      </div>
      {shop && customerFeatureIsExpired() ? (
        <RestrictionInfo
          title={'Customer support feature not available '}
          content={'To get customer support options, contact support team..'}
        />
      ) : (
        <>
          <div className={styles.changeable}>
            <div className={styles.filterSec}>
              <div className={styles.headTitle}>
                <h2 className={styles.subTitle}>Customers</h2>
              </div>
              <div className={styles.filerInputSec}>
                {userHasCustomersWritePermission && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ backgroundColor: '#ff851b' }}
                    className={styles.actionBtn}
                    onClick={openCreateNewCustomerDialog}
                  >
                    <NoteAddIcon className={styles.actionBtnIcon} />
                    New Customer
                  </Button>
                )}
                <div className={styles.searchSec}>
                  <input
                    type="text"
                    value={filterText}
                    onChange={(e) => {
                      setFilterText(e.target.value);
                      setIsEnableSearchRefreshButton(true);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setOffset(0);
                        setPage(0);
                        getCustomers({ _offset: 0 });
                      }
                    }}
                    className={styles.searchInput}
                    placeholder="Search Customers"
                  />
                  <Tooltip title="Refresh">
                    <IconButton
                      className={styles.iconBtn}
                      onClick={() => {
                        setOffset(0);
                        setPage(0);
                        getCustomers({ _offset: 0 });
                      }}
                      disabled={!isEnableSearchRefreshButton}
                    >
                      <SyncIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          {customers && customers.length ? (
            <Paper className={styles.tableWrapper}>
              <DataTable columns={headerData} rows={customers} deactiveRow={true} />
              <TablePagination
                rowsPerPageOptions={[20, 50]}
                component="div"
                count={count}
                rowsPerPage={limit}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          ) : (
            !loadingIndicator && (
              <Info
                title={'No customers to list'}
                content={
                  'You have no customers to list with current filter configuration. Please clear the filters or create a customer'
                }
              />
            )
          )}
          {editData && (
            <EditCustomerDialog
              closeDialog={closeEditCustomerDialog}
              editData={editData}
              reloadCustomers={getCustomers}
              customerGroups={customerGroups}
            />
          )}
          {isShowCreateDialog && (
            <NewCustomerDialog
              customerGroups={customerGroups}
              closeCreateNewCustomerDialog={closeCreateNewCustomerDialog}
              reloadCustomers={getCustomers}
            />
          )}
        </>
      )}
    </div>
  );
}

export default withConsoleBase(Customers);
