import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useHistory } from 'react-router-dom';
import './DownloadInvoice.css';
import { ROUTES } from '../../../const';
import { STORAGE_KEYS, BACKEND_URL } from '../../../const';
import PrintIcon from '@mui/icons-material/Print';

const DownloadInvoice = ({ bill }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadInvoice = (type) => {
    billToPdf(bill, type);
    handleMenuClose();
  };

  const billToPdf = async (bill, type) => {
    const authToken = localStorage.getItem(STORAGE_KEYS.TOKEN);
    const shopId = localStorage.getItem(STORAGE_KEYS.SHOP_ID);

    if (!authToken || !shopId) {
      console.error('Missing auth token or shop ID');
      return;
    }

    if (!bill || !bill.bill_no) {
      console.error('Invalid bill object:', bill);
      return;
    }

    const url = `${BACKEND_URL}/reports/sales/details/pdf/?token=${authToken}&shop_id=${shopId}&bill_no=${
      bill.bill_no
    }${type ? `&invoice_format=${type}` : ''}`;

    window.open(url);
  };

  const handleEditRedirect = () => {
    const billId = bill?.id;

    if (!billId) {
      console.error('Bill ID is missing or invalid:', bill);
      return;
    }

    history.push(`${ROUTES.MANAGE_SALES}?id=${billId}`);
    handleMenuClose();
  };

  return (
    <div className="menu-container">
      <Tooltip title="Print Invoice">
        <PrintIcon className="iconBtn" onClick={() => handleDownloadInvoice()} />
      </Tooltip>
      <div className="more-options-container">
        <Tooltip title="More options">
          <IconButton aria-label="more options" onClick={handleMenuClick}>
            <MoreVertIcon className="iconBtn" />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          MenuListProps={{
            className: 'menu-list',
          }}
        >
          <MenuItem onClick={() => handleDownloadInvoice('a4')} className="menu-item">
            Invoice A4
          </MenuItem>
          <MenuItem onClick={() => handleDownloadInvoice('a5')} className="menu-item">
            Invoice A5
          </MenuItem>
          <MenuItem onClick={() => handleDownloadInvoice('3-inch')} className="menu-item">
            Invoice 3 Inch
          </MenuItem>
          <MenuItem onClick={handleEditRedirect} className="menu-item">
            EDIT
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default DownloadInvoice;
