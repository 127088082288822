import React, { useContext, useEffect, useState } from 'react';
import { Button, Tooltip, Grid } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable/';
import NewVendorDialog from '../../popups/NewVendorDialog';
import EditVendorDialog from '../../popups/EditVendorDialog';
import VendorServices from '../../../services/VendorServices';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Loader from '../../utils/Loading';
import setDelay from '../../../helpers/LoadingDelay';
import Info from '../../utils/Alert/Info';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';
import { ShopContext } from '../../../Context/ShopContext';
import { UserContext } from '../../../Context/UserContext';
import { ROUTES, USER_PERMISSIONS } from '../../../const';
import { useHistory } from 'react-router-dom';
import { Paper, TablePagination, Typography } from '@mui/material';
import { featureIsExpired } from '../../utils/FeatureValidityExpire';
import { AlertMessagesContext } from 'react-alert-messages';
import ShopsHelper from '../../../helpers/ShopsHelper';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

function Vendors() {
  const { shop } = useContext(ShopContext);
  const { user } = useContext(UserContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const history = useHistory();

  const [vendors, setVendors] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [showAddVendonDialog, setShowAddVendonDialog] = useState(false);
  const [editData, setEditData] = useState('');
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [count, setCount] = useState(0);
  const [isEnableSearchRefreshButton, setIsEnableSearchRefreshButton] = useState(false);

  const userHasPurchaseWritePermission = user?.shop_permissions?.purchase_permission === USER_PERMISSIONS.WRITE;
  const featureValidity = shop?.customer_support_validity;

  const toggleEditTab = (rowData = '') => {
    editData ? setEditData('') : setEditData(rowData);
  };

  const openCreateVendorDialog = () => {
    setShowAddVendonDialog(true);
  };
  const closeCreateVendorDialog = () => {
    setShowAddVendonDialog(false);
  };

  const onCreateVendor = () => {
    getVendors();
    setShowAddVendonDialog(false);
  };

  const getVendors = async ({ _offset = offset, _limit = limit } = {}) => {
    setLoadingIndicator(true);
    try {
      const res = await VendorServices.getVendorsWithCount({
        offset: _offset,
        limit: _limit,
        search: filterText,
      });
      setVendors(res?.data);
      setCount(res?.count);
      setIsEnableSearchRefreshButton(false);
    } catch (err) {
      postAlertMessage({
        text: err.message,
        type: 'failed',
      });
    }
    setDelay(setLoadingIndicator);
  };

  const redirectToVendorDetails = async (data) => {
    history.push(`${ROUTES.VENDORS}/${data.id}`);
  };

  const getBalance = (data) => {
    const balance = ShopsHelper.getAmountFormatted(shop, data?.balance);
    let balanceStyle;
    if (balance < 0) balanceStyle = { color: 'red' };
    else balanceStyle = { color: 'green' };
    return (
      <Typography variant="body2" style={balanceStyle} gutterBottom>
        {balance}
      </Typography>
    );
  };

  const headerData = [
    {
      label: 'ID',
      id: 'id',
      type: 'text',
    },
    {
      label: 'Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Mobile',
      id: 'mobile',
      type: 'text',
    },
    {
      label: 'Code',
      id: 'code',
      type: 'text',
    },
    {
      label: 'Address',
      id: 'address',
      type: 'text',
    },
    {
      label: 'Balance',
      id: 'balance',
      type: 'callback',
      viewRender: (data) => getBalance(data),
    },
    {
      label: 'Actions',
      id: 'actions',
      type: 'callback',
      viewRender: (item) => {
        return (
          <Grid className={styles.btnGroup}>
            <Tooltip title="View Details" onClick={() => redirectToVendorDetails(item)}>
              <RemoveRedEyeIcon className={styles.iconButton} />
            </Tooltip>
            {userHasPurchaseWritePermission && (
              <>
                <Tooltip title="Edit Vendor" onClick={() => toggleEditTab(item)}>
                  <EditIcon className={styles.iconButton} />
                </Tooltip>
              </>
            )}
          </Grid>
        );
      },
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    let offset = newPage * limit;
    setOffset(offset);
    getVendors({ _offset: offset });
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(event.target.value);
    getVendors({ _limit: limit });
  };

  useEffect(() => {
    if (!shop?.id || featureIsExpired(featureValidity)) return;
    getVendors();
    //eslint-disable-next-line
  }, [shop?.id, featureValidity]);
  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Vendor<span className={styles.menuTitle}>Management</span>
        </h2>
      </div>
      {shop && featureIsExpired(featureValidity) ? (
        <RestrictionInfo
          title={'Vendor support feature not available '}
          content={'To get vendor support options, contact support team..'}
        />
      ) : (
        <>
          <div className={styles.changeable}>
            <div className={styles.filterSec}>
              <div className={styles.headTitle}>
                <h2 className={styles.subTitle}>Vendors</h2>
              </div>

              <div className={styles.filerInputSec}>
                {userHasPurchaseWritePermission && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ backgroundColor: '#ff851b' }}
                    className={styles.actionBtn}
                    onClick={openCreateVendorDialog}
                  >
                    <NoteAddIcon className={styles.actionBtnIcon} />
                    New Vendor
                  </Button>
                )}
                <div className={styles.searchSec}>
                  <input
                    type="text"
                    value={filterText}
                    onChange={(e) => {
                      setFilterText(e.target.value);
                      setIsEnableSearchRefreshButton(true);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setOffset(0);
                        setPage(0);
                        getVendors({ _offset: 0 });
                      }
                    }}
                    className={styles.searchInput}
                    placeholder="search items"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ backgroundColor: '#00a65a' }}
                    onClick={() => {
                      setOffset(0);
                      setPage(0);
                      getVendors({ _offset: 0 });
                    }}
                    disabled={!isEnableSearchRefreshButton}
                  >
                    Refresh
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {vendors && vendors.length ? (
            <Paper className={styles.tableWrapper}>
              <DataTable columns={headerData} rows={vendors} toggleEditTab={toggleEditTab} deactiveRow={true} />
              <TablePagination
                rowsPerPageOptions={[20, 50]}
                component="div"
                count={count}
                rowsPerPage={limit}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          ) : (
            !loadingIndicator && (
              <Info
                title={'No vendors to list'}
                content={
                  'You have no vendors to list with current filter configuration. Please clear the filters or create a vendor'
                }
              />
            )
          )}
          {editData && <EditVendorDialog toggleEditTab={toggleEditTab} editData={editData} getVendors={getVendors} />}
          {showAddVendonDialog && <NewVendorDialog onClose={closeCreateVendorDialog} onSuccess={onCreateVendor} />}
        </>
      )}
    </div>
  );
}

export default withConsoleBase(Vendors);
