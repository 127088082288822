import BackendService from './BackendService';

async function getShift(id) {
  return BackendService.getData(`/shifts/${id}/`);
}
async function getShifts(params) {
  return BackendService.getData('/shifts/', {}, params);
}
async function getShiftTransactions(id) {
  return BackendService.getData(`/shifts/${id}/transactions/`);
}
async function getShiftDetails(id) {
  return BackendService.getData(`/shifts/${id}/`);
}

const ShiftService = {
  getShift,
  getShifts,
  getShiftTransactions,
  getShiftDetails,
};

export default ShiftService;
