import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import SearchIcon from '@material-ui/icons/Search';
import Loader from '../../utils/Loading';
import Info from '../../utils/Alert/Info';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { ShopContext } from '../../../Context/ShopContext';
import DateFnsUtils from '@date-io/date-fns';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import { AlertMessagesContext } from 'react-alert-messages';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { saveAs } from 'file-saver';
import CsvHelper from '../../../helpers/CsvHelper';
import SyncIcon from '@mui/icons-material/Sync';
import ShopsHelper from '../../../helpers/ShopsHelper';
import Grayout from '../../utils/GrayOut/Grayout';
import PurchaseSummaryReportServices from '../../../services/PurchaseSummaryReportServices';
import PrintIcon from '@mui/icons-material/Print';

function PurchaseItemSummary() {
  const { shop } = useContext(ShopContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [filterFromDate, setFilterFromDate] = useState(new Date());
  const [filterToDate, setFilterToDate] = useState(new Date());

  const [searchText, setSearchText] = useState('');
  const [purchases, setPurchase] = useState('');
  const [filteredPurchases, setFilteredPurchases] = useState([]);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [isShowGenerateButton, setIsShowGenerateButton] = useState(null);
  const [prevFilterData, setPrevFilterData] = useState({
    filterFromDate,
    filterToDate,
  });

  const CSV_COLUMNS = {
    date: 'Date',
    plu: 'Plu',
    name: 'Name',
    hsn: 'HSN',
    rate: 'Rate',
    quantity: 'Quantity',
    tax: 'Total tax',
    total: 'Amount',
  };

  const toCsvBtnPressed = async () => {
    const csv = CsvHelper.getString(purchases, CSV_COLUMNS);
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `purchase-items-${new Date().toISOString()}.csv`);

    postAlertMessage({
      text: 'Exported to excel successfully',
      type: 'success',
    });
  };

  const _getPurchasesReportSummart = (purchases) => {
    let totalAmount = 0;
    let totalTax = 0;
    purchases.forEach((purchase) => {
      totalAmount += purchase?.total;
      totalTax += purchase?.tax;
    });
    return {
      totalAmount: ShopsHelper.getAmountFormatted(shop, totalAmount),
      totalTax: ShopsHelper.getAmountFormatted(shop, totalTax),
    };
  };

  const headerData = [
    {
      label: 'PLU',
      id: 'plu',
      type: 'text',
      viewRender: (item) => item?.vendor?.name ?? item?.vendor_name_old ?? '-',
    },
    {
      label: 'Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Purchase Date',
      id: 'date',
      type: 'text',
    },
    {
      label: 'Rate',
      id: 'rate',
      type: 'text',
    },
    {
      label: 'Quantity',
      id: 'quantity',
      type: 'text',
    },
    {
      label: 'Total Tax',
      id: 'tax',
      type: 'text',
    },
    {
      label: 'Total Amount',
      id: 'total',
      type: 'floatAmount',
    },
  ];

  const _getLoadPurchaseRequestParams = useCallback(() => {
    return {
      date: DateTimeHelpers.convertDateToDMY(filterFromDate),
      to_date: DateTimeHelpers.convertDateToDMY(filterToDate),
    };
  }, [filterFromDate, filterToDate]);

  const getPurchase = useCallback(async () => {
    setLoadingIndicator(true);
    try {
      const data = _getLoadPurchaseRequestParams();
      const res = await PurchaseSummaryReportServices.getPurchaseItemSummary(data);
      setPurchase(res);
      setFilteredPurchases(res);
    } catch (err) {
      postAlertMessage({ text: err.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  }, [_getLoadPurchaseRequestParams, postAlertMessage]);

  const generatePurchaseHistory = () => {
    getPurchase();
    setIsShowGenerateButton(false);
    setPrevFilterData({
      filterFromDate,
      filterToDate,
    });
  };

  const isAnyChangeOnReportsFilters = useCallback(() => {
    return filterFromDate !== prevFilterData.filterFromDate || filterToDate !== prevFilterData.filterToDate;
  }, [filterFromDate, filterToDate, prevFilterData.filterFromDate, prevFilterData.filterToDate]);

  useEffect(() => {
    generatePurchaseHistory();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isShowGenerateButton === null) return;
    setIsShowGenerateButton(isAnyChangeOnReportsFilters());
  }, [isAnyChangeOnReportsFilters, isShowGenerateButton]);

  const handleSearch = (value) => {
    setSearchText(value);
    setFilteredPurchases(
      (purchases || []).filter(
        (purchase) => purchase.name.toLowerCase().includes(value) || purchase.date.includes(value)
      )
    );
  };

  const summary = _getPurchasesReportSummart(filteredPurchases);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <span className={styles.title}>
          Reports<span className={styles.menuTitle}>Generation</span>
        </span>
        <div style={{ justifyContent: 'flex-end', marginRight: '10px' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Print Report</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a' }}
            onClick={window.print}
          >
            <PrintIcon className={styles.actionBtnIcon} />
            Print
          </Button>
        </div>
        <div style={{ justifyContent: 'flex-end' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Export As</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a', marginRight: 5 }}
            onClick={toCsvBtnPressed}
          >
            <ImportExportIcon className={styles.actionBtnIcon} />
            Csv
          </Button>
        </div>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>
              Purchase Item Summary Report for{'  '}
              {DateTimeHelpers.convertDateToDMY(filterFromDate)}
              {'  '}to{'  '}
              {DateTimeHelpers.convertDateToDMY(filterToDate)}
            </h2>
          </div>
          <div className={styles.filerInputSec}>
            <div className={styles.searchSec}>
              <input
                type="text"
                value={searchText}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                className={styles.searchInput}
                placeholder="search purchase"
              />
              <Tooltip title="Search" placement="bottom">
                <SearchIcon className={styles.searchIcon} />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>From</label>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  variant="outlined"
                  format="dd/MM/yyyy"
                  id="date-picker-from"
                  className={styles.dateBox}
                  value={filterFromDate}
                  onChange={(date) => {
                    setFilterFromDate(date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>To</label>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  variant="outlined"
                  format="dd/MM/yyyy"
                  id="date-picker-to"
                  className={styles.dateBox}
                  value={filterToDate}
                  onChange={(date) => {
                    setFilterToDate(date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Load Purchases</label>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={generatePurchaseHistory}
                disabled={isShowGenerateButton === false}
              >
                <SyncIcon className={styles.actionBtnIcon} />
                Generate
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isShowGenerateButton === null ? (
        <Info
          title={'Set filters and click load button'}
          content={
            'Purchase history are generated based on the filters. Please set filters and click load purchase button to get purchase history.'
          }
        />
      ) : (
        purchases &&
        (filteredPurchases && filteredPurchases.length ? (
          <div className={styles.tableContainer}>
            <Grayout open={isShowGenerateButton} />
            <DataTable
              columns={headerData}
              rows={filteredPurchases}
              summary={{ plu: 'Total', total: summary.totalAmount, tax: summary.totalTax }}
            />
          </div>
        ) : (
          !loadingIndicator && (
            <Info
              title={'You have no purchases to list'}
              content={"You haven't any purchase to list with given properties"}
            />
          )
        ))
      )}
    </div>
  );
}

export default withConsoleBase(PurchaseItemSummary);
