import React, { useContext, useEffect } from 'react';
import styles from './styles.module.css';
import Loader from '../../utils/Loading';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Grid } from '@mui/material';
import { OnlinePurchaseContext } from '../../../Context/OnlinePurchaseContext';
import { ROUTES, STORAGE_KEYS } from '../../../const';
import PaymentService from '../../../services/PaymentService';
import { AlertMessagesContext } from 'react-alert-messages';

function PaymentProcessing() {
  const history = useHistory();
  const { purchaseData } = useContext(OnlinePurchaseContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const processFormBasedPayment = (formData) => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = formData.url;

    for (const [key, value] of Object.entries(formData.secrets)) {
      const encRequestInput = document.createElement('input');
      encRequestInput.type = 'hidden';
      encRequestInput.name = key;
      encRequestInput.value = value;
      form.appendChild(encRequestInput);
    }

    document.body.appendChild(form);
    form.submit();
  };

  const handleBackNavigation = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  const handleBuyButton = async () => {
    try {
      if (!localStorage.getItem(STORAGE_KEYS.TOKEN)) {
        history.push(ROUTES.SIGNUP);
        return;
      }

      if (purchaseData.amount <= 0) {
        handleBackNavigation();
        return;
      }

      const resp = await PaymentService.initiatePayment(purchaseData);
      if (resp && resp?.payment_url) {
        window.location.href = resp?.payment_url;
        return;
      }
      if (resp && resp?.form_data) {
        processFormBasedPayment(resp?.form_data);
        return;
      }
      postAlertMessage({ text: 'Failed to initiate payment', type: 'failed' });
    } catch (error) {
      // Update UI and let the user retry the payment again
      handleBackNavigation();
      postAlertMessage({ text: error.message, type: 'failed' });
    }
  };

  useEffect(() => {
    handleBuyButton();
    //eslint-disable-next-line
  }, []);

  return (
    <Grid container className={styles.container}>
      <Loader isOpen={true} />
    </Grid>
  );
}

export default PaymentProcessing;
