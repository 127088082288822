import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import React from 'react';
import AlertMessagesProvider from 'react-alert-messages';
import './App.css';
import DashboardPage from './components/pages/Dashboard/Dashboard';
import SettingsPage from './components/pages/Settings/SettingsPage';
import StockReportPage from './components/pages/stock/StockReport';
import StockDamagePage from './components/pages/StockDamage/StockDamage';
import NewStockDamagePage from './components/pages/StockDamage/NewStockDamage';
import ItemSummaryReportPage from './components/pages/Report/ItemSummary';
import PaymentModeSummaryReportPage from './components/pages/Report/PaymentModeSummaryReport';
import ItemisedBillSummaryReportPage from './components/pages/Report/ItemisedBill';
import BillSummaryReportPage from './components/pages/Report/BillSummary';
import ProfitBillSummaryReportPage from './components/pages/Report/ProfitBillSummary';
import ProfitItemSummaryReportPage from './components/pages/Report/ProfitItemSummary';
import ProfitItemisedBillSummaryReportPage from './components/pages/Report/ProfitItemisedBill';
import ProfitDayWiseReport from './components/pages/Report/ProfitDayWiseReport';
import DayWiseReport from './components/pages/Report/DayWiseReport';
import LoginPage from './components/pages/Login/Login';
import ForgetPasswordPage from './components/pages/Login/ForgetPassword';
import NewAccountPage from './components/pages/Register/NewAccount';
import PlansPage from './components/pages/Login/Plans';
import ItemsListPage from './components/pages/Products/ItemsList';
import ItemsCategoriesPage from './components/pages/Products/ItemsCategries';
import DevicesPage from './components/pages/Devices/Devices';
import TerminalPage from './components/pages/Terminals/Terminals';
import PurchaseHistoryPage from './components/pages/Purchase/PurchaseHistory';
import AddPurchasePage from './components/pages/Purchase/AddPurchase';
import SubscriptionPage from './components/pages/Subscription&Payment/Subscription';
import PermissionDetail from './components/pages/Settings/PermissionDetail';
import PaymentCallbackPage from './components/pages/Subscription&Payment/PaymentCallback';
import CustomersPage from './components/pages/Customers/Customers';
import SalesPersonPage from './components/pages/SalesPerson';
import TablesPage from './components/pages/Tables';
import ExpensesPage from './components/pages/Expenses/Expenses';
import PageNotFound from './components/pages/Error/PageNotFound';
import PrivateRoute from './components/utils/PrivateRoute';
import SelectedTabProvider from './Context/SelectedTabContext';
import ShopProvider from './Context/ShopContext';
import UserProvider from './Context/UserContext';
import { ROUTES } from './const';
import CustomerDetailsPage from './components/pages/Customers/CustomerDetails';
import VendorsPage from './components/pages/Vendors/Vendors';
import VendorDetailsPage from './components/pages/Vendors/VendorDetails';
import ThemeProvider from './Context/ThemeContext';
import OnlinePurchaseProvider from './Context/OnlinePurchaseContext';
import OnboardingPage from './components/pages/Login/Onboarding';
import AppSetupPage from './components/pages/Login/AppSetup';
import RegisterSuccess from './components/pages/Login/RegisterSuccess';
import DynamicHeader from './components/utils/DynamicHeader/DynamicHeader';
import TerminalSettingsPage from './components/pages/Terminals/TerminalSettings';
import PricingPage from './components/pages/Register/RegisterPlan';
import AddonPage from './components/pages/Register/Addon';
import PaymentProcessingPage from './components/pages/Register/PaymentProcessing';
import PaymentStatusPage from './components/pages/Register/PaymentStatus';
import StaffSalesReport from './components/pages/Report/StaffSalesReport';
import CategorySalesReport from './components/pages/Report/CategorySalesReport';
import ReturnedSalesSummary from './components/pages/Report/ReturnedSalesSummary';
import ReturnedItemSummary from './components/pages/Report/ReturnedItemSummary';
import ReturnedBillItemSummary from './components/pages/Report/ReturnedBillItemSummary';
import PurchaseSummary from './components/pages/Report/PurchaseSummary';
import PurchaseItemSummary from './components/pages/Report/PurchaseItemSummary';
import VendorPurchaseSummary from './components/pages/Report/VendorPurchaseSummary';
import SalesTaxSummary from './components/pages/Report/SalesTaxSummary';
import PurchaseTaxSummary from './components/pages/Report/PurchaseTaxSummary';
import HSNSummaryReport from './components/pages/Report/HSNSummaryReport';
import OrderTypeSummary from './components/pages/Report/OrderTypeSummary';
import CustomerGroupPage from './components/pages/Customers/CustomerGroup';
import OrderActionsPage from './components/pages/OrderActions/OrderActions';
import AddSalesPage from './components/pages/Sales/AddSales';
import ShiftReportSummaryPage from './components/pages/Report/ShiftSummary';

function App() {
  return (
    <AlertMessagesProvider>
      <ThemeProvider>
        <DynamicHeader />
        <Router>
          <SelectedTabProvider>
            <OnlinePurchaseProvider>
              <Switch>
                <Route exact path={ROUTES.ANY} component={LoginPage} />
                <Route exact path={ROUTES.CONSOLE} component={LoginPage} />
                <Route exact path={ROUTES.LOGIN} component={LoginPage} />
                <Route exact path={ROUTES.RESET_PASSWORD} component={ForgetPasswordPage} />
                <Route exact path={ROUTES.ONBOARDING} component={OnboardingPage} />
                <Route exact path={ROUTES.APP_SETUP} component={AppSetupPage} />
                <Route exact path={ROUTES.SHOP_CREATED} component={RegisterSuccess} />
                <Route exact path={ROUTES.PAGE_404} component={PageNotFound} />
                <Route exact path={ROUTES.SIGNUP} component={NewAccountPage} />
                <Route exact path={ROUTES.PLANS} component={PlansPage} />
                <Route exact path={ROUTES.PRICING} component={PricingPage} />
                <Route exact path={ROUTES.ADD_ONS} component={AddonPage} />
                <Route exact path={ROUTES.PROCESS_PAYMENT} component={PaymentProcessingPage} />
                <Route exact path={ROUTES.PAYMENT_STATUS} component={PaymentStatusPage} />
                <ShopProvider>
                  <UserProvider>
                    <PrivateRoute exact path={ROUTES.DASHBOARD} component={DashboardPage} />
                    <PrivateRoute exact path={ROUTES.ITEMS} component={ItemsListPage} />
                    <PrivateRoute exact path={ROUTES.CATEGORIES} component={ItemsCategoriesPage} />
                    <PrivateRoute exact path={ROUTES.DEVICES} component={DevicesPage} />
                    <PrivateRoute exact path={ROUTES.TERMINALS} component={TerminalPage} />
                    <PrivateRoute exact path={ROUTES.TERMINAL_SETTINGS} component={TerminalSettingsPage} />
                    <PrivateRoute exact path={ROUTES.CUSTOMERS} component={CustomersPage} />
                    <PrivateRoute exact path={ROUTES.CUSTOMER_GROUP} component={CustomerGroupPage} />
                    <PrivateRoute exact path={ROUTES.CUSTOMER_DETAILS} component={CustomerDetailsPage} />
                    <PrivateRoute exact path={ROUTES.VENDORS} component={VendorsPage} />
                    <PrivateRoute exact path={ROUTES.VENDOR_DETAILS} component={VendorDetailsPage} />
                    <PrivateRoute exact path={ROUTES.SALES_PERSON} component={SalesPersonPage} />
                    <PrivateRoute exact path={ROUTES.TABLES} component={TablesPage} />
                    <PrivateRoute exact path={ROUTES.MANAGE_SALES} component={AddSalesPage} />
                    <PrivateRoute exact path={ROUTES.PURCHASE_HISTORY} component={PurchaseHistoryPage} />
                    <PrivateRoute exact path={ROUTES.NEW_PURCHASE} component={AddPurchasePage} />
                    <PrivateRoute exact path={ROUTES.EDIT_PURCHASE} component={AddPurchasePage} />
                    <PrivateRoute exact path={ROUTES.EXPENSES} component={ExpensesPage} />
                    <PrivateRoute exact path={ROUTES.DAMAGE_HISTORY} component={StockDamagePage} />
                    <PrivateRoute exact path={ROUTES.ORDER_ACTIONS} component={OrderActionsPage} />
                    <PrivateRoute exact path={ROUTES.NEW_DAMAGE} component={NewStockDamagePage} />
                    <PrivateRoute exact path={ROUTES.ITEM_SUMMARY} component={ItemSummaryReportPage} />
                    <PrivateRoute exact path={ROUTES.ITEMISED_BILL} component={ItemisedBillSummaryReportPage} />
                    <PrivateRoute exact path={ROUTES.BILL_SUMMARY} component={BillSummaryReportPage} />
                    <PrivateRoute exact path={ROUTES.PAYMENT_MODE_REPORT} component={PaymentModeSummaryReportPage} />
                    <PrivateRoute exact path={ROUTES.PROFIT_ITEM_SUMMARY} component={ProfitItemSummaryReportPage} />
                    <PrivateRoute exact path={ROUTES.STAFF_SALES_REPORT} component={StaffSalesReport} />
                    <PrivateRoute exact path={ROUTES.CATEGORY_SALES_REPORT} component={CategorySalesReport} />
                    <PrivateRoute exact path={ROUTES.RETURNED_BILL_SUMMARY} component={ReturnedSalesSummary} />
                    <PrivateRoute exact path={ROUTES.RETURNED_ITEM_SUMMARY} component={ReturnedItemSummary} />
                    <PrivateRoute exact path={ROUTES.RETURNED_SALE_ITEM_SUMMARY} component={ReturnedBillItemSummary} />
                    <PrivateRoute exact path={ROUTES.PURCHASE_SUMMARY} component={PurchaseSummary} />
                    <PrivateRoute exact path={ROUTES.PURCHASE_ITEM_SUMMARY} component={PurchaseItemSummary} />
                    <PrivateRoute exact path={ROUTES.VENDON_PURCHASE_SUMMARY} component={VendorPurchaseSummary} />
                    <PrivateRoute exact path={ROUTES.SALES_TAX_SUMMARY} component={SalesTaxSummary} />
                    <PrivateRoute exact path={ROUTES.PURCHASE_TAX_SUMMARY} component={PurchaseTaxSummary} />
                    <PrivateRoute exact path={ROUTES.HSN_SUMMARY} component={HSNSummaryReport} />
                    <PrivateRoute exact path={ROUTES.SHIFT_SUMMARY} component={ShiftReportSummaryPage} />
                    <PrivateRoute exact path={ROUTES.ORDER_TYPE_SALES_REPORT} component={OrderTypeSummary} />
                    <PrivateRoute
                      exact
                      path={ROUTES.PROFIT_ITEMISED_BILL}
                      component={ProfitItemisedBillSummaryReportPage}
                    />
                    <PrivateRoute exact path={ROUTES.PROFIT_BILL_SUMMARY} component={ProfitBillSummaryReportPage} />
                    <PrivateRoute exact path={ROUTES.DAY_WISE_REPORT} component={DayWiseReport} />
                    <PrivateRoute exact path={ROUTES.PROFIT_DAY_WISE_REPORT} component={ProfitDayWiseReport} />
                    <PrivateRoute exact path={ROUTES.STOCK_REPORT} component={StockReportPage} />
                    <PrivateRoute exact path={ROUTES.SETTINGS} component={SettingsPage} />
                    <PrivateRoute exact path={ROUTES.SETTINGS_TAB} component={SettingsPage} />
                    <PrivateRoute exact path={ROUTES.SETTINGS_SUB_TAB} component={SettingsPage} />
                    <PrivateRoute exact path={ROUTES.SUBSCRIPTION_PLANS} component={SubscriptionPage} />
                    <PrivateRoute exact path={ROUTES.PERMISSION_DETAIL} component={PermissionDetail} />
                    <PrivateRoute exact path={ROUTES.PAYMENT_CALLBACK} component={PaymentCallbackPage} />
                  </UserProvider>
                </ShopProvider>
                <Route path="/*">
                  <Redirect to={ROUTES.PAGE_404} />
                </Route>
              </Switch>
            </OnlinePurchaseProvider>
          </SelectedTabProvider>
        </Router>
      </ThemeProvider>
    </AlertMessagesProvider>
  );
}

export default App;
